var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "20px" } },
    [
      _c(
        "el-form",
        {
          ref: "goodsForm",
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "120px",
            rules: _vm.couponRule
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关联平台:", prop: "platIds" } },
            [
              _c("el-cascader", {
                attrs: { options: _vm.plateformData, props: _vm.optionProps },
                model: {
                  value: _vm.formData.platIds,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "platIds", $$v)
                  },
                  expression: "formData.platIds"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠券标题:", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  clearable: "",
                  maxlength: "30",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "优惠券名称:", prop: "internal_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: {
                  clearable: "",
                  maxlength: "30",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.formData.internal_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "internal_name", $$v)
                  },
                  expression: "formData.internal_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片:", prop: "resource_cover", required: "" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.imageAction,
                    "show-file-list": false,
                    "before-upload": _vm.imageBeforeUpload,
                    "on-success": _vm.handleAvatarSuccess
                  }
                },
                [
                  _vm.imageUrl
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl }
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon"
                      })
                ]
              ),
              _c("span", { staticClass: "help" }, [
                _vm._v(
                  "尺寸800*800, 大小不超过1M（图片仅支持 .jpg, .png, .jpeg格式）"
                )
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "优惠分类:", prop: "discount_type", required: "" }
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: { display: "flex", margin: "8px 0 0" },
                  on: { change: _vm.changeType },
                  model: {
                    value: _vm.formData.discount_type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "discount_type", $$v)
                    },
                    expression: "formData.discount_type"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: { flex: "1", margin: "0" },
                      attrs: { label: 2 }
                    },
                    [_vm._v("立减")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { flex: "1", margin: "0" },
                      attrs: { label: 3 }
                    },
                    [_vm._v("折扣")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { flex: "1" },
                      on: { change: _vm.changeDiscount },
                      model: {
                        value: _vm.formData.discount_text.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.discount_text, "type", $$v)
                        },
                        expression: "formData.discount_text.type"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("减")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(0)
                              }
                            },
                            model: {
                              value: _vm.discountData[0],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 0, $$v)
                              },
                              expression: "discountData[0]"
                            }
                          }),
                          _vm._v("元")
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("最高")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(1)
                              }
                            },
                            model: {
                              value: _vm.discountData[1],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 1, $$v)
                              },
                              expression: "discountData[1]"
                            }
                          }),
                          _vm._v("元")
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: 3 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("最低")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(2)
                              }
                            },
                            model: {
                              value: _vm.discountData[2],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 2, $$v)
                              },
                              expression: "discountData[2]"
                            }
                          }),
                          _vm._v("元")
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: 4 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("满")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(3)
                              }
                            },
                            model: {
                              value: _vm.discountData[3],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 3, $$v)
                              },
                              expression: "discountData[3]"
                            }
                          }),
                          _vm._v("元 减"),
                          _c("el-input", {
                            staticStyle: { width: "12%", margin: "0 10px" },
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(3)
                              }
                            },
                            model: {
                              value: _vm.discountData[4],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 4, $$v)
                              },
                              expression: "discountData[4]"
                            }
                          }),
                          _vm._v("元")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { flex: "1" },
                      on: { change: _vm.changeDiscount },
                      model: {
                        value: _vm.formData.discount_text.type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData.discount_text, "type", $$v)
                        },
                        expression: "formData.discount_text.type"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 5 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("打")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(5)
                              }
                            },
                            model: {
                              value: _vm.discountData[5],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 5, $$v)
                              },
                              expression: "discountData[5]"
                            }
                          }),
                          _vm._v("折")
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: 6 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("最高打")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(6)
                              }
                            },
                            model: {
                              value: _vm.discountData[6],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 6, $$v)
                              },
                              expression: "discountData[6]"
                            }
                          }),
                          _vm._v("折")
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: 7 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("最低打")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(7)
                              }
                            },
                            model: {
                              value: _vm.discountData[7],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 7, $$v)
                              },
                              expression: "discountData[7]"
                            }
                          }),
                          _vm._v("折")
                        ],
                        1
                      ),
                      _c(
                        "el-radio",
                        { attrs: { label: 8 } },
                        [
                          _c("span", { staticClass: "radioText" }, [
                            _vm._v("满")
                          ]),
                          _c("el-input", {
                            staticClass: "radioInput",
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(8)
                              }
                            },
                            model: {
                              value: _vm.discountData[8],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 8, $$v)
                              },
                              expression: "discountData[8]"
                            }
                          }),
                          _vm._v("元 打"),
                          _c("el-input", {
                            staticStyle: { width: "12%", margin: "0 10px" },
                            on: {
                              focus: function($event) {
                                return _vm.onFocus(8)
                              }
                            },
                            model: {
                              value: _vm.discountData[9],
                              callback: function($$v) {
                                _vm.$set(_vm.discountData, 9, $$v)
                              },
                              expression: "discountData[9]"
                            }
                          }),
                          _vm._v("折")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发放数量:", prop: "circulation" } },
            [
              _c("el-input", {
                staticStyle: { width: "60%" },
                attrs: { clearable: "", placeholder: "最多10000000张" },
                model: {
                  value: _vm.formData.circulation,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "circulation", _vm._n($$v))
                  },
                  expression: "formData.circulation"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "有效期:", prop: "validity", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeTime },
                  model: {
                    value: _vm.formData.validity.type,
                    callback: function($$v) {
                      _vm.$set(_vm.formData.validity, "type", $$v)
                    },
                    expression: "formData.validity.type"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: { "margin-top": "2px" },
                      attrs: { label: 4 }
                    },
                    [
                      _vm._v("自定义固定时间 "),
                      _c("el-date-picker", {
                        staticStyle: { padding: "0 10px" },
                        attrs: {
                          "picker-options": _vm.datePickerOptions,
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.times,
                          callback: function($$v) {
                            _vm.times = $$v
                          },
                          expression: "times"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: 3 } },
                    [
                      _vm._v("领取当天起 "),
                      _c("el-input", {
                        staticStyle: { width: "25%", margin: "0 10px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.selectDay,
                          callback: function($$v) {
                            _vm.selectDay = _vm._n($$v)
                          },
                          expression: "selectDay"
                        }
                      }),
                      _vm._v("天内可用 ")
                    ],
                    1
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: 2 } },
                    [
                      _vm._v("领取后起 "),
                      _c("el-input", {
                        staticStyle: { width: "25%", margin: "0 10px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.selectTimes,
                          callback: function($$v) {
                            _vm.selectTimes = _vm._n($$v)
                          },
                          expression: "selectTimes"
                        }
                      }),
                      _vm._v("小时内可用 ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _vm._v("若设定自定义固定时间，编辑保存后仅对后续领取的券生效。")
              ]),
              _c("div", { staticStyle: { "line-height": "7px" } }, [
                _vm._v(
                  " 若设置领取后n天/小时可用，编辑保存后仅对后续领取的券生效 "
                )
              ])
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "描述:" } }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.description,
                  expression: "formData.description"
                }
              ],
              staticClass: "instructions",
              staticStyle: { width: "70%" },
              attrs: { maxlength: "300", cols: "100", rows: "10" },
              domProps: { value: _vm.formData.description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.formData, "description", $event.target.value)
                }
              }
            })
          ]),
          _c("el-form-item", { attrs: { label: "推广说明:" } }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.formData.popularize_explain,
                  expression: "formData.popularize_explain"
                }
              ],
              staticClass: "instructions",
              staticStyle: { width: "70%" },
              attrs: { maxlength: "300", cols: "100", rows: "10" },
              domProps: { value: _vm.formData.popularize_explain },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.formData,
                    "popularize_explain",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                _vm._l(_vm.selectStatus, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { margin: "2% 0 2% 5%", "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "9%" },
              attrs: { size: "medium", type: "info" },
              on: { click: _vm.closeDialog }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "2%", width: "9%" },
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.savePlatform }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }